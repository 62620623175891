import { Link } from "gatsby";
import React from "react";

const WorkHeader = () => (
  <header class="work-header">
    <h1>
      <Link to="/">
        <span class="brand">&lt;ps/&gt;</span>
        <span class="backlink">&laquo; Back to home</span>
      </Link>
    </h1>
  </header>
);

export default WorkHeader;
