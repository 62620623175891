import React from "react";

import Layout from "./layout";
import WorkHeader from "./work-header";

const WorkLayout = ({ children, pageContext }) => (
  <Layout>
    <WorkHeader />
    <section class="work-sample">
      <h2><small>{pageContext.frontmatter.keytech}:</small> {pageContext.frontmatter.title}</h2>
      {children}
    </section>
  </Layout>
);

export default WorkLayout;
