import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Blueprint is an assessment mapping tool for higher education. Administrators can:`}</p>
    <ul>
      <li parentName="ul">{`Manage University-wide student learning outcomes`}</li>
      <li parentName="ul">{`Manage courses for the university including setting course-level learning outcomes`}</li>
      <li parentName="ul">{`Set up the University structure including divisions such as departments, schools, and centers`}</li>
      <li parentName="ul">{`Add programs inside of any division including`}
        <ul parentName="li">
          <li parentName="ul">{`adding program-level student learning outcomes`}</li>
          <li parentName="ul">{`managing a list of courses that are required or optional as a part of the program`}</li>
          <li parentName="ul">{`map course learning outcomes to program learning outcomes`}</li>
          <li parentName="ul">{`run reports to see how the program maps up to university outcomes and how complete course outcomes mapping is`}</li>
        </ul>
      </li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/work/blueprint-programs.png",
        "alt": null
      }}></img></p>
    <p>{`Blueprint uses PHP/MySQL and the CodeIgniter framework along with some minimal front-end functionality with JavaScript and jQuery.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/blueprint-program-courses.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      